import React, { lazy, Suspense } from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import "./i18n"
import { languages } from "./i18n"
import "./index.css"
import { RootLayout } from "./layouts/RootLayout"
import { LoadingSpinner } from "./ui/LoadingSpinner"

// const lazed = (path: string) => {
//   const name = path.split("/").at(-1)
//   return lazy(() =>
//     /* @vite-ignore */ import(path).then((module) => ({
//       default: module[name as keyof typeof module],
//     }))
//   )
// }
const LazyHomePage = lazy(() =>
  import("./pages/homepage/HomePage").then((module) => ({
    default: module.HomePage,
  }))
)
const LazyNotFoundPage = lazy(() =>
  import("./pages/not-found/NotFound").then((module) => ({
    default: module.NotFoundPage,
  }))
)
const LazyPrivacyPolicyPage = lazy(() =>
  import("./pages/privacy-policy/PrivacyPolicy").then((module) => ({
    default: module.PrivacyPolicyPage,
  }))
)
const LazyContactUsPage = lazy(() =>
  import("./pages/contact-us/ContactUsPage").then((module) => ({
    default: module.ContactUsPage,
  }))
)
const LazyServicesPage = lazy(() =>
  import("./pages/services/ServicesPage").then((module) => ({
    default: module.ServicesPage,
  }))
)
const LazyTermOfServicePage = lazy(() =>
  import("./pages/term-of-service/TermOfServicePage").then((module) => ({
    default: module.TermOfServicePage,
  }))
)

const LazyAboutUsPage = lazy(() =>
  import("./pages/about-us/AboutUsPage").then((module) => ({
    default: module.AboutUsPage,
  }))
)
const LazyMaster = lazy(() =>
  import("./layouts/Master").then((module) => ({
    default: module.Master,
  }))
)
const routers = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        element: <LazyMaster />,
        path: "/:lang",
        children: [
          {
            index: true,
            element: <LazyHomePage />,
          },
          {
            path: "/:lang/about-us",
            element: <LazyAboutUsPage />,
          },
          {
            path: "/:lang/services",
            element: <LazyServicesPage />,
          },
          {
            path: "/:lang/contact-us",
            element: <LazyContactUsPage />,
          },
        ],
      },
      {
        children: [
          {
            path: "/:lang/privacy-policy",
            element: <LazyPrivacyPolicyPage />,
          },
          {
            path: "/:lang/term-of-service",
            element: <LazyTermOfServicePage />,
          },
        ],
      },
      {
        path: "/:lang/*", // Redirect any unknown paths
        element: <LazyNotFoundPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to={`/${languages[0]}`} replace />, // Redirect root to default language
  },
])

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={routers} />
  </React.StrictMode>
)
