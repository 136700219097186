import { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, ScrollRestoration, useParams } from "react-router-dom"
import { TankStackContext } from "../contexts/TankStackContext"
import { ThemeProvider } from "../contexts/ThemeProvider"
import { languages } from "../i18n"
import "react-toastify/dist/ReactToastify.css"
// Supports weights 200-900
import "@fontsource/cairo"
// Supports weights 100-900
import "@fontsource/montserrat"
import { LoadingSpinner } from "~/ui/LoadingSpinner"
import { Helmet } from "react-helmet"
import { ToastContainer } from "react-toastify"

const MetaTags = () => {
  const { lang } = useParams<{ lang: string }>()
  const { t } = useTranslation()

  return (
    <Helmet>
      <link rel="canonical" href={import.meta.env.VITE_PUBLIC_BASE_URL} />
      <meta property="og:url" content={import.meta.env.VITE_PUBLIC_BASE_URL} />
      <link rel="icon" type="image/svg+xml" href="/favicon.ico" />
      <meta
        name="theme-color"
        content="#0c7364"
        media="(prefers-color-scheme: light)"
      />
      <meta
        name="theme-color"
        content="#0c7364"
        media="(prefers-color-scheme: dark)"
      />
      <meta property="og:image" content="/opengraph-images/root-layout.png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image"
        content="/opengraph-images/root-layout-twitter.png"
      />

      <title lang={lang}>{t("metaData.root.title")}</title>
      <meta name="description" content={t("metaData.root.description")} />
      <meta property="og:title" content={t("metaData.root.ogTitle")} />
      <meta
        property="og:description"
        content={t("metaData.root.ogDescription")}
      />
      <meta property="og:image:alt" content={t("metaData.root.ogTitle")} />
      <meta name="twitter:title" content={t("metaData.root.twitterTitle")} />
      <meta
        name="twitter:description"
        content={t("metaData.root.twitterDescription")}
      />
      <meta
        name="twitter:image:alt"
        content={t("metaData.root.twitterTitle")}
      />
    </Helmet>
  )
}

export const RootLayout = () => {
  const { lang } = useParams()
  const [, i18n] = useTranslation()
  const setLanguage = (lang: string) => {
    const htmlTag = document.querySelector("html") as HTMLHtmlElement

    i18n.changeLanguage(lang)
    const dir = lang === "ar" ? "rtl" : "ltr"

    htmlTag.setAttribute("dir", dir)
    htmlTag.setAttribute("lang", lang)
  }
  useEffect(() => {
    setLanguage(lang ?? languages[0])
  }, [lang])

  return (
    <>
      <MetaTags />
      <TankStackContext>
        <ThemeProvider>
          <ScrollRestoration />
          <Suspense
            fallback={
              <section className="fixed inset-0 z-50 flex size-full items-center justify-center">
                <LoadingSpinner />
              </section>
            }
          >
            <Outlet />
          </Suspense>
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </ThemeProvider>
      </TankStackContext>
    </>
  )
}
